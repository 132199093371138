import React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

import { Logo } from "../atoms";

const Home = () => {
  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div className="px-4 py-5 my-5 text-center flex-grow-1">
        <Logo />
        <p className="lead mb-4">
          Proteja su capital! Ahorre dólares digitales. Envíe y reciba USDT y
          BUSD.
        </p>
        <div className="col-lg-6 mx-auto">
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <Link to="/login">
              <button
                type="button"
                className="btn btn-primary btn-lg px-4 gap-3"
              >
                Iniciar Sesión
              </button>
            </Link>
            <Link to="/register">
              <button
                type="button"
                className="btn btn-outline-secondary btn-lg px-4"
              >
                Registro
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Container>
  );
};

export { Home };
