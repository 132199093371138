import React, { useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm, Controller } from "react-hook-form";

import { DashboardTemplate } from "../organisms";
import { ErrorAlert, Card, LoadingBtn } from "../atoms";
import useResource from "../hooks/useResource";
import { AuthContext } from "../context/auth-context";

const TOKENS = ["USDT", "BUSD", "BNB"];

const AdminTxs = () => {
  const auth = useContext(AuthContext);
  const [token, setToken] = useState();
  const [transfer, isLoading, error, clearError] = useResource();
  const network = process.env.REACT_APP_NETWORK;
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      amount: "",
      account: "",
    },
  });

  const onSubmit = async (data) => {
    clearError();
    try {
      await transfer(
        process.env.REACT_APP_DEZENTRAL_API_BASE_URL + "/api/users/transfer",
        {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${auth.token}`,
        },
        "POST",
        {
          token: token === "BNB" ? "ETH" : token,
          to: data.account,
          amount: Number(data.amount),
        }
      );
      reset();
    } catch (err) {
      console.log(err);
    }
  };

  const handleRadioClick = (e) => {
    setToken(e.target.id);
  };

  return (
    <DashboardTemplate>
      <Card>
        <div className="p-5">
          <h4 className="text-muted">Envíos a través de la red {network}</h4>
          <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <Form.Group className="mb-3" controlId="formGroupAmount">
                  <Form.Control
                    type="number"
                    {...field}
                    required
                    min={0}
                    step={0.00001}
                    placeholder="Ingrese el monto"
                  />
                </Form.Group>
              )}
            />
            <Controller
              name="account"
              control={control}
              render={({ field }) => (
                <Form.Group className="mb-3" controlId="formGroupAccount">
                  <Form.Control
                    type="text"
                    {...field}
                    required
                    placeholder="Ingrese la dirección"
                    pattern="0x+[A-F,a-f,0-9]{40}"
                  />
                </Form.Group>
              )}
            />
            <div className="mb-4">
              {TOKENS.map((item, index) => {
                return (
                  <Controller
                    key={index}
                    name="radio"
                    control={control}
                    render={({ field }) => (
                      <Form.Check
                        inline
                        label={item}
                        name="radio-group"
                        type="radio"
                        id={item}
                        onClick={handleRadioClick}
                        {...field}
                        required
                      />
                    )}
                  />
                );
              })}
            </div>
            {isLoading ? <LoadingBtn /> : <Button type="submit">Enviar</Button>}
          </Form>
        </div>
      </Card>
      {error && (
        <div className="mt-4">
          <ErrorAlert text={error.message} details={error.details} />
        </div>
      )}
    </DashboardTemplate>
  );
};

export { AdminTxs };
