import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

const FilterDropDown = ({ status, setStatus, filters, variant }) => {
  let filterObj = filters.filter((item) => item.state === status);

  const handleClick = (requestStatus) => {
    setStatus(requestStatus);
  };

  return (
    <DropdownButton
      id="dropdown-item-button"
      title={filterObj[0].title}
      variant={variant}
    >
      {filters.map((item, index) => {
        return (
          <Dropdown.Item
            key={index}
            as="button"
            onClick={() => handleClick(filters[index].state)}
          >
            {filters[index].title}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
};

export { FilterDropDown };
