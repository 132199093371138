import React, { useState, useContext, useEffect } from "react";
import Button from "react-bootstrap/Button";

import { DashboardTemplate } from "../organisms";
import { Table, FilterDropDown } from "../molecules";
import { ErrorAlert, Loader } from "../atoms";
import useResource from "../hooks/useResource";
import { AuthContext } from "../context/auth-context";

const STATUS_FILTERS = [
  {
    title: "Solicitudes nuevas",
    state: "PENDING",
  },
  {
    title: "Solicitudes aprobadas",
    state: "COMPLETED",
  },
];

const TOKEN_FILTERS = [
  {
    title: "BUSD",
    state: "BUSD",
  },
  {
    title: "USDT",
    state: "USDT",
  },
];

const SendToCard = () => {
  const [status, setStatus] = useState("PENDING");
  const [token, setToken] = useState("USDT");
  const [requests, setRequests] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const auth = useContext(AuthContext);
  const [getRequests, isLoading, error] = useResource();

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await getRequests(
          process.env.REACT_APP_DEZENTRAL_API_BASE_URL +
            `/api/users/withdrawals?token=${token}&status=${status}`,
          {
            Authorization: `Bearer ${auth.token}`,
          }
        );
        setRequests(response);
      } catch (err) {
        console.log(err);
      }
    };
    fetchRequests();
  }, [status, token, isUpdated]);

  const updateList = () => setIsUpdated(!isUpdated);

  const dataFilter = (data, status, token) => {
    return data?.filter(
      (item) => item.status === status && item.token === token
    );
  };

  return (
    <DashboardTemplate>
      <div className="d-flex flex-column flex-md-row gap-4 mb-4">
        <FilterDropDown
          status={status}
          setStatus={setStatus}
          filters={STATUS_FILTERS}
        />
        <FilterDropDown
          status={token}
          setStatus={setToken}
          filters={TOKEN_FILTERS}
          variant="success"
        />
        <div>
          <Button variant="success" onClick={updateList}>
            Actualizar
          </Button>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <ErrorAlert />
      ) : (
        <Table
          data={dataFilter(requests, status, token)}
          filter={status}
          updateList={updateList}
        />
      )}
    </DashboardTemplate>
  );
};

export { SendToCard };
