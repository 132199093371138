import React from "react";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

const LoadingBtn = ({ className }) => {
  return (
    <Button variant="primary" disabled className={className}>
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      <span className="visually-hidden">Loading...</span>
    </Button>
  );
};

export { LoadingBtn };
