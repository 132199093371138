import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";

import useResource from "../hooks/useResource";
import { LoadingBtn } from "../atoms/LoadingBtn";

const Recovery = () => {
  const [recoverPwd, isLoading, error, clearError] = useResource();
  const [sent, setSent] = useState(false);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (data) => {
    clearError();
    await recoverPwd(
      process.env.REACT_APP_DEZENTRAL_API_BASE_URL +
        "/api/auth/recover_password/",
      { "Content-Type": "application/json;charset=utf-8" },
      "POST",
      data
    );
    setSent(true);
    try {
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh", backgroundColor: "#38635A" }}
    >
      <Card className="p-5">
        {!sent ? (
          <>
            <h2 className="text-center ">Olvidó su contraseña?</h2>
            {error && <div className="text-center text-danger">Error</div>}
            <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Control
                      type="email"
                      placeholder="Ingrese su correo"
                      {...field}
                      required
                    />
                  </Form.Group>
                )}
              />
              {isLoading ? (
                <LoadingBtn className="w-100" />
              ) : (
                <>
                  <Button variant="primary" className="w-100" type="submit">
                    Enviar
                  </Button>
                  <div className="text-center mt-3">
                    <Link to="/login">Regresar</Link>
                  </div>
                </>
              )}
            </Form>
          </>
        ) : (
          <>
            <h5 className="text-center">Correo enviado</h5>
            <p>Revise su correo y abra el link para continuar</p>
          </>
        )}
      </Card>
    </Container>
  );
};

export { Recovery };
