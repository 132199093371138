import { DateTime } from "luxon";

export const parseAmount = (amount) =>
  amount
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const parseStrAmount = (amount) => {
  return parseAmount(parseFloat(amount));
};

export const parseDate = (date) => {
  return DateTime.fromSeconds(parseInt(date)).toLocaleString(
    DateTime.DATETIME_MED
  );
};

export const trimHash = (hash) => {
  return hash.substring(0, 7) + "..." + hash.slice(-5);
};

export const parseISODate = (date) => {
  if (date) {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
  }
};
