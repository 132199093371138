import React from "react";
import Image from "react-bootstrap/Image";
import Placeholder from "react-bootstrap/Placeholder";

import { Card } from "../atoms";
import busdLogo from "../images/busd.svg";
import usdtLogo from "../images/usdt.svg";
import usdcLogo from "../images/usdt.svg";
import { parseStrAmount } from "../utils/parsing";

const BalanceCard = ({ symbol, amount, highlighted, isLoading }) => {
  let color;
  let logo;

  switch (symbol) {
    case "USDC":
      color = "primary";
      logo = usdcLogo;
      break;
    case "USDT":
      color = "success";
      logo = usdtLogo;
      break;
    case "BUSD":
      color = "warning";
      logo = busdLogo;
      break;
    default:
      break;
  }
  return (
    <Card highlighted={highlighted}>
      <div
        style={{ width: "180px" }}
        className="d-flex flex-direction-row justify-content-between"
      >
        <div>
          <p className={`m-0 text-${color}`}>{symbol}</p>
          {!isLoading ? (
            <p
              style={{ fontWeight: "bold", fontSize: "1.5em" }}
              className="m-0"
            >
              {parseStrAmount(amount)}
            </p>
          ) : (
            <Placeholder
              as="p"
              animation="glow"
              xs={12}
              style={{ fontSize: "1.5em", margin: "0" }}
            >
              <Placeholder xs={12} />
            </Placeholder>
          )}
        </div>
        <Image src={logo} className="w-25" alt="logo" />
      </div>
    </Card>
  );
};

export { BalanceCard };
