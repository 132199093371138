import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useParams } from "react-router-dom";

import useResource from "../hooks/useResource";
import { LoadingBtn } from "../atoms/LoadingBtn";

const newPassword = yup.object().shape({
  password: yup.string().required("Requerido"),
  passwordConfirmation: yup
    .string()
    .required("Requerido")
    .oneOf([yup.ref("password"), null], "La contraseña debe coincidir"),
});

const PwdReset = () => {
  const [updatePwd, isLoading, error, clearError] = useResource();
  const [sent, setSent] = useState(false);
  const { token } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(newPassword),
  });

  const onSubmit = async (data) => {
    clearError();
    await updatePwd(
      process.env.REACT_APP_DEZENTRAL_API_BASE_URL +
        "/api/auth/update_password",
      { "Content-Type": "application/json;charset=utf-8" },
      "POST",
      {
        password: data.password,
        token,
      }
    );
    setSent(true);
    try {
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh", backgroundColor: "#38635A" }}
    >
      <Card className="p-5">
        {!sent ? (
          <>
            <h2 className="text-center ">Cree una nueva contraseña</h2>
            {error && <div className="text-center text-danger">Error</div>}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group mb-3 mt-4">
                <input
                  name="password"
                  type="password"
                  placeholder="Nueva contraseña"
                  {...register("password")}
                  className={`form-control ${
                    errors.password ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.password?.message}
                </div>
              </div>

              <div className="form-group mb-3">
                <input
                  name="passwordConfirmation"
                  type="password"
                  placeholder="Confirmar nueva contraseña"
                  {...register("passwordConfirmation")}
                  className={`form-control ${
                    errors.passwordConfirmation ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.passwordConfirmation?.message}
                </div>
              </div>
              <div className="form-group mb-3">
                {isLoading ? (
                  <LoadingBtn className="w-100" />
                ) : (
                  <button type="submit" className="btn btn-primary w-100">
                    Cambiar
                  </button>
                )}
              </div>
            </form>
          </>
        ) : (
          <>
            <h5 className="text-center">La contraseña ha sido cambiada</h5>
            <div className="text-center mt-3">
              <Link to="/login">Iniciar Sesión</Link>
            </div>
          </>
        )}
      </Card>
    </Container>
  );
};

export { PwdReset };
