import useSWR from "swr";

const URL =
  process.env.REACT_APP_DEZENTRAL_API_BASE_URL + "/api/users/balances";

const fetcher = async (url, token) => {
  const res = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const json = await res.json();
  return json;
};

export const useBalance = (token) => {
  const { data, error } = useSWR([URL, token], fetcher, {
    refreshInterval: 20000,
  });

  return {
    balance: data,
    isLoading: !error && !data,
    error,
  };
};
