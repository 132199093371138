import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import { default as BModal } from "react-bootstrap/Modal";

import useResource from "../hooks/useResource";
import { LoadingBtn, ErrorAlert } from "../atoms";
import { AuthContext } from "../context/auth-context";

const Modal = (props) => {
  const { referredBy, name, email, id, address, phone } = props.data;
  const [approveUser, isLoading, error, clearError] = useResource();
  const auth = useContext(AuthContext);

  const onSubmit = async () => {
    try {
      await approveUser(
        process.env.REACT_APP_DEZENTRAL_API_BASE_URL + "/api/users/approve/",
        {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${auth.token}`,
        },
        "POST",
        {
          id,
          // TODO: disable card data in back
          card: "0000000000000000",
          pin: "0000",
        }
      );
      props.onHide();
    } catch (err) {
      console.log(err);
    } finally {
      props.updateList();
    }
  };

  return (
    <BModal
      show={props.show}
      onHide={props.onHide}
      onShow={clearError}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="mt-4">
        <BModal.Header closeButton>
          <BModal.Title id="contained-modal-title-vcenter">
            Verificación de usuario
          </BModal.Title>
        </BModal.Header>
        <BModal.Body>
          {error ? (
            <>
              <ErrorAlert />
              <p className="text-danger mt-4">{error?.message}</p>
              <p className="text-danger">{error?.details}</p>
            </>
          ) : (
            <>
              <p>ID: {id}</p>
              <p>Nombre: {name}</p>
              <p>Correo: {email}</p>
              <p>Dirección: {address}</p>
              <p>Teléfono: {phone}</p>
              <p>Referido por: {referredBy}</p>
            </>
          )}
        </BModal.Body>
        <BModal.Footer>
          <Button onClick={props.onHide} variant="danger">
            Cancelar
          </Button>
          {isLoading ? (
            <LoadingBtn />
          ) : (
            <Button onClick={onSubmit} disabled={error}>
              Aceptar
            </Button>
          )}
        </BModal.Footer>
      </div>
    </BModal>
  );
};

export { Modal };
