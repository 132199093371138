import React, { useState, useContext, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";

import { BalanceCard, TxTable, WithdrawalsTable } from "../molecules";
import { ErrorAlert, Loader, Card, LoadingBtn } from "../atoms";
import useResource from "../hooks/useResource";
import { AuthContext } from "../context/auth-context";
import { ConfirmModal } from "./ConfirmModal";

const ACCOUNT = [
  {
    symbol: "USDT",
  },
  {
    symbol: "BUSD",
  },
];

const Balance = ({ data, isLoading }) => {
  const auth = useContext(AuthContext);
  const [isVisible, setIsVisible] = useState(false);
  const [key, setKey] = useState("");
  const [tabKey, setTabKey] = useState("receive");
  const [txs, setTxs] = useState();
  const [withdrawals, setWithdrawals] = useState();
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showWithdrawalModal, setShowWithdrawalModal] = useState(false);
  const [transfer, isSending, transferError, clearTransferError] =
    useResource();
  const [withdraw, isWithdrawing, withdrawError, clearWithdrawError] =
    useResource();
  const [getTxs, isLoadingTxs, txsError] = useResource();
  const [getWithdrawals, isLoadingWithdrawals, withdrawalError] = useResource();
  const network = process.env.REACT_APP_NETWORK;

  const {
    minTransferUsd,
    minWithdrawalUsd,
    transferFeeUsd,
    withdrawalFeeRate,
  } = auth.serviceInfo;

  useEffect(() => {
    setTabKey("receive");
  }, [key]);

  const handleClick = (e) => {
    if (!isVisible) {
      setKey(e);
      setIsVisible(true);
    } else if (key === e) {
      setIsVisible(false);
      setKey(null);
    } else if (key !== e) {
      setKey(e);
    }
  };

  const handleTabSelect = (k) => {
    setTabKey(k);
    if (k === "send") fetchTxs();
    if (k === "cashout") fetchWithdrawals();
  };

  const fetchTxs = async () => {
    try {
      const response = await getTxs(
        process.env.REACT_APP_DEZENTRAL_API_BASE_URL +
          `/api/users/transactions?token=${key}`,
        {
          Authorization: `Bearer ${auth.token}`,
        }
      );
      setTxs(response);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchWithdrawals = async () => {
    try {
      const response = await getWithdrawals(
        process.env.REACT_APP_DEZENTRAL_API_BASE_URL +
          `/api/users/withdrawals?token=${key}`,
        {
          Authorization: `Bearer ${auth.token}`,
        }
      );
      setWithdrawals(response);
    } catch (err) {
      console.log(err);
    }
  };

  const TransferForm = () => {
    const { control, handleSubmit, reset, getValues } = useForm({
      defaultValues: {
        amount: "",
        account: "",
      },
    });

    const openModal = () => setShowTransferModal(true);
    const closeModal = () => setShowTransferModal(false);

    const onTransfer = async (data) => {
      clearTransferError();
      try {
        await transfer(
          process.env.REACT_APP_DEZENTRAL_API_BASE_URL + "/api/users/transfer",
          {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${auth.token}`,
          },
          "POST",
          { token: key, to: data.account, amount: Number(data.amount) }
        );
        fetchTxs();
      } catch (err) {
        console.log(err);
      } finally {
        reset();
        closeModal();
      }
    };

    return (
      <>
        <ConfirmModal
          show={showTransferModal}
          onHide={closeModal}
          action={handleSubmit(onTransfer)}
          isLoading={isSending}
          mode="transfer"
          token={key}
          data={getValues("amount")}
        />
        <Form className="mt-4" onSubmit={handleSubmit(openModal)}>
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <Form.Group className="mb-3" controlId="formGroupAmount">
                <Form.Control
                  type="number"
                  {...field}
                  required
                  min={minTransferUsd}
                  step={0.01}
                  placeholder="Ingrese el monto"
                />
              </Form.Group>
            )}
          />
          <Controller
            name="account"
            control={control}
            render={({ field }) => (
              <Form.Group className="mb-3" controlId="formGroupAccount">
                <Form.Control
                  type="text"
                  {...field}
                  required
                  placeholder="Ingrese la dirección"
                  pattern="0x+[A-F,a-f,0-9]{40}"
                />
              </Form.Group>
            )}
          />
          {isSending ? <LoadingBtn /> : <Button type="submit">Enviar</Button>}
        </Form>
      </>
    );
  };

  const WithdrawalForm = () => {
    const { control, handleSubmit, reset, getValues } = useForm({
      defaultValues: {
        amount: "",
      },
    });

    const onWithdrawal = async (data) => {
      clearWithdrawError();
      try {
        await withdraw(
          process.env.REACT_APP_DEZENTRAL_API_BASE_URL +
            "/api/users/withdrawals",
          {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${auth.token}`,
          },
          "POST",
          { token: key, amount: Number(data.amount) }
        );
        fetchWithdrawals();
      } catch (err) {
        console.log(err);
      } finally {
        reset();
        closeModal();
      }
    };

    const openModal = () => setShowWithdrawalModal(true);
    const closeModal = () => setShowWithdrawalModal(false);

    return (
      <>
        <ConfirmModal
          show={showWithdrawalModal}
          onHide={closeModal}
          action={handleSubmit(onWithdrawal)}
          isLoading={isWithdrawing}
          mode="withdrawal"
          token={key}
          data={getValues("amount")}
        />
        <Form className="mt-4" onSubmit={handleSubmit(openModal)}>
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <Form.Group className="mb-3" controlId="formGroupAmount">
                <Form.Control
                  type="number"
                  {...field}
                  required
                  min={minWithdrawalUsd}
                  step={0.01}
                  placeholder="Ingrese el monto"
                />
              </Form.Group>
            )}
          />
          {isWithdrawing ? (
            <LoadingBtn />
          ) : (
            <Button type="submit">Enviar</Button>
          )}
        </Form>
      </>
    );
  };

  return (
    <div>
      <h3 className="text-muted">Portafolio</h3>
      <div className="d-grid gap-5 d-sm-flex my-4">
        {ACCOUNT.map((e, i) => {
          return (
            <div key={i} onClick={() => handleClick(e.symbol)}>
              <BalanceCard
                isLoading={isLoading}
                highlighted={key === e.symbol}
                symbol={e.symbol}
                amount={data ? data[e.symbol.toLowerCase()] : "-"}
              />
            </div>
          );
        })}
      </div>
      <div className={`my-5 ${!isVisible && "d-none"}`}>
        <Card>
          <Tabs
            id="controlled-tab-example"
            activeKey={tabKey}
            onSelect={(k) => handleTabSelect(k)}
            className="mb-3"
            fill
          >
            <Tab eventKey="receive" title={`Recibir ${key}`}>
              <div className="p-5 ">
                <div className="text-muted mb-3">
                  Esta es la dirección de tu billetera:{" "}
                  <span>
                    <h4>{auth.userInfo.account}</h4>
                  </span>
                </div>
                <div className="text-muted">
                  Red:{" "}
                  <span>
                    <h4>{network}</h4>
                  </span>
                </div>
              </div>
            </Tab>
            <Tab eventKey="send" title={`Enviar ${key}`}>
              <div className="p-5">
                <h5 className="text-muted">
                  Enviar {key} a otras billeteras a través de la red {network}
                </h5>
                <div className="text-muted">
                  Envio minimo {minTransferUsd} {key}, comisión:{" "}
                  {transferFeeUsd} {key}
                </div>
                {transferError && (
                  <ErrorAlert
                    text={transferError.message}
                    details={transferError.details}
                  />
                )}
                {TransferForm()}
              </div>
              <hr />
              <div className="mt-2">
                {isLoadingTxs ? (
                  <Loader />
                ) : txsError ? (
                  <ErrorAlert />
                ) : (
                  <TxTable
                    data={txs}
                    token={key}
                    wallet={auth.userInfo.account.toLowerCase()}
                  />
                )}
              </div>
            </Tab>
            <Tab eventKey="cashout" title="Enviar a tarjeta">
              <div className="p-5 ">
                <h5 className="text-muted">Enviar {key} a tarjeta débito</h5>
                <div className="text-muted">
                  Envio minimo {minWithdrawalUsd} {key}, comisión:{" "}
                  {withdrawalFeeRate * 100}%
                </div>
                {withdrawError && (
                  <ErrorAlert
                    text={withdrawError.message}
                    details={withdrawError.details}
                  />
                )}
                {WithdrawalForm()}
              </div>
              <hr />
              <div className="mt-2">
                {isLoadingWithdrawals ? (
                  <Loader />
                ) : withdrawalError ? (
                  <ErrorAlert />
                ) : (
                  <WithdrawalsTable data={withdrawals} />
                )}
              </div>
            </Tab>
          </Tabs>
        </Card>
      </div>
    </div>
  );
};

export { Balance };
