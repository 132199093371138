import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { AuthContext } from "../context/auth-context";

const ConfirmModal = ({
  show,
  action,
  onHide,
  isLoading,
  mode,
  token,
  data,
}) => {
  const auth = useContext(AuthContext);
  const {
    minTransferUsd,
    minWithdrawalUsd,
    transferFeeUsd,
    withdrawalFeeRate,
  } = auth.serviceInfo;

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {mode === "transfer"
            ? "Envío a otras billeteras"
            : "Envío a tarjeta débito"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {mode === "transfer" ? (
          <>
            <p>
              Monto a enviar: {data} {token}
            </p>
            <p>
              Comisión: {transferFeeUsd} {token}
            </p>
            <p>
              Monto a recibir: {data - transferFeeUsd} {token}
            </p>
          </>
        ) : (
          <>
            <p>
              Monto a enviar: {data} {token}
            </p>
            <p>
              Comisión: {withdrawalFeeRate * data} {token}
            </p>
            <p>
              Monto a recibir: {data * (1 - withdrawalFeeRate)} {token}
            </p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={isLoading} onClick={onHide}>
          Cerrar
        </Button>
        <Button disabled={isLoading} variant="danger" onClick={action}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { ConfirmModal };
