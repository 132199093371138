import React from "react";
import Table from "react-bootstrap/Table";

import { parseDate, trimHash, parseStrAmount } from "../utils/parsing";

const TxTable = ({ data, wallet, token }) => {
  if (!data || data.length === 0) {
    return (
      <h4 className="text-center text-secondary my-5">
        Aún no hay transacciones
      </h4>
    );
  }

  const sortedData = data.sort((a, b) => {
    return Number(a.timestamp) - Number(b.timestamp);
  });

  const tokenLabel = token === "ETH" ? "BNB" : token;

  return (
    <Table borderless hover responsive size="sm">
      <thead>
        <tr>
          <th className="text-center">Fecha</th>
          <th className="text-center">Origen</th>
          <th className="text-center">Destino</th>
          <th className="text-center">Confirmaciones</th>
          <th className="text-center">Detalles</th>
          <th className="text-center">Monto</th>
        </tr>
      </thead>
      <tbody>
        {sortedData
          .map((item, index) => {
            const isWithdrawal = item.from === wallet;

            return (
              <tr key={index}>
                <td className="text-center">{parseDate(item.timestamp)}</td>
                <td className="text-center">{trimHash(item.from)}</td>
                <td className="text-center">{trimHash(item.to)}</td>
                <td className="text-center">{item.confirmations}</td>
                <td className="text-center">
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href={`${process.env.REACT_APP_BLOCK_EXPLORER_URL}/tx/${item.hash}`}
                  >
                    {trimHash(item.hash)}
                  </a>
                </td>
                <td
                  className={`text-center ${
                    isWithdrawal ? "text-danger" : "text-success"
                  }`}
                >
                  {isWithdrawal && "-"}
                  {Number(item.value).toFixed(5)} {tokenLabel}
                </td>
              </tr>
            );
          })
          .reverse()}
      </tbody>
    </Table>
  );
};

export { TxTable };
