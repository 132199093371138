import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

const TopUpModal = ({
  show,
  onHide,
  data,
  action,
  isLoading,
  error,
  amount,
}) => {
  const { name, referredBy, email, balance = { usdt: "??", bnb: "??" } } = data;

  const handleTopUp = async () => {
    await action(data);
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Recargar BNB
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="d-flex justify-content-center my-4">
            <Spinner animation="border" role="status" variant="success">
              <span className="visually-hidden">Cargando...</span>
            </Spinner>
          </div>
        ) : error ? (
          <div className="d-flex justify-content-center my-4">
            <h4 className="text-danger">Error, inténtelo más tarde</h4>
          </div>
        ) : (
          <>
            <p>Confirme la información para continuar.</p>
            <p>
              El equivalente a {amount} USD en BNB será transferido al siguiente
              usuario.
            </p>
            <p>Nombre: {name}</p>
            <p>Correo: {email}</p>
            <p>Referido por: {referredBy}</p>
            <p>Saldo USDT: {balance["usdt"]}</p>
            <p>Saldo BUSD: {balance["busd"]}</p>
            <p>Saldo BNB: {balance["bnb"]}</p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={isLoading} onClick={onHide}>
          Cerrar
        </Button>
        <Button disabled={isLoading} onClick={handleTopUp} variant="danger">
          Recargar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { TopUpModal };
