import React from "react";
import { default as BCard } from "react-bootstrap/Card";

const Card = ({ children, highlighted }) => {
  return (
    <BCard className={`p-3 shadow ${highlighted && "border border-primary"}`}>
      {children}
    </BCard>
  );
};

export { Card };
