import React, { useContext } from "react";

import { AuthContext } from "../context/auth-context";
import { PageTemplate } from "../organisms";

const Profile = () => {
  const auth = useContext(AuthContext);
  const { _id, address, email, name, phone, account, referredBy } =
    auth.userInfo;
  return (
    <PageTemplate>
      <h3 className="text-muted">Perfil</h3>
      <div className="mt-4">
        <h5 className="text-muted">ID</h5>
        <p className="text-muted">{_id}</p>
        <h5 className="text-muted">Billetera</h5>
        <p className="text-muted">{account}</p>
        <h5 className="text-muted">Nombre</h5>
        <p className="text-muted">{name}</p>
        <h5 className="text-muted">Correo electrónico</h5>
        <p className="text-muted">{email}</p>
        <h5 className="text-muted">Dirección</h5>
        <p className="text-muted">{address}</p>
        <h5 className="text-muted">Teléfono</h5>
        <p className="text-muted">{phone}</p>
        <h5 className="text-muted">Referido por</h5>
        <p className="text-muted">{referredBy}</p>
      </div>
    </PageTemplate>
  );
};

export { Profile };
