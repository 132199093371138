import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import useResource from "../hooks/useResource";
import { AuthContext } from "../context/auth-context";
import { LoadingBtn } from "../atoms/LoadingBtn";

const LoginForm = ({ admin }) => {
  const [loginReq, isLoading, error, clearError] = useResource();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    clearError();
    try {
      const responseData = await loginReq(
        process.env.REACT_APP_DEZENTRAL_API_BASE_URL + "/api/users/login",
        { "Content-Type": "application/json;charset=utf-8" },
        "POST",
        data
      );
      const { isApproved, token, isAdmin } = responseData;
      let { variables, ...userInfo } = responseData;
      isApproved && auth.login(userInfo, token, variables);
      isApproved
        ? isAdmin
          ? navigate("/admin/requests")
          : navigate("/portfolio")
        : navigate("/processing");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {error && (
        <p className="text-center text-danger">
          Error, revise su nombre de usuario y contraseña
        </p>
      )}
      <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Control
                type="email"
                placeholder="Ingrese su correo"
                {...field}
                required
              />
            </Form.Group>
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <Form.Group className="mb-3" controlId="formGroupPassword">
              <Form.Control
                type="password"
                placeholder="Ingrese su contraseña"
                {...field}
                required
              />
            </Form.Group>
          )}
        />
        {isLoading ? (
          <LoadingBtn className="w-100" />
        ) : (
          <Button variant="primary" className="w-100" type="submit">
            Iniciar Sesión
          </Button>
        )}
        {!admin && (
          <>
            <div className="text-center mt-3">
              Aun no tiene cuenta?{" "}
              <span>
                <Link to="/register">Regístrese</Link>
              </span>
            </div>
            <div className="text-center mt-3">
              <Link to="/recover_password">Olvidó su contraseña?</Link>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export { LoginForm };
