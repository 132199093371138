import React from "react";
import Alert from "react-bootstrap/Alert";

const ErrorAlert = ({ text, details }) => {
  const label = text || "Error! Intente más tarde";
  return (
    <Alert variant="danger" className="text-center m-0">
      <Alert.Heading className="m-0">{label}</Alert.Heading>
      {details && <p>{details}</p>}
    </Alert>
  );
};

export { ErrorAlert };
