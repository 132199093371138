import React, { useState, useContext, useEffect } from "react";

import { DashboardTemplate } from "../organisms";
import { TxTable, FilterDropDown } from "../molecules";
import { parseStrAmount } from "../utils/parsing";
import { AuthContext } from "../context/auth-context";
import useResource from "../hooks/useResource";
import { ErrorAlert, Loader } from "../atoms";
import { useBalance } from "../hooks/useBalance";

const TOKEN_FILTERS = [
  {
    title: "USDT",
    state: "USDT",
  },
  {
    title: "BUSD",
    state: "BUSD",
  },
  {
    title: "BNB",
    state: "ETH",
  },
];

const History = () => {
  const auth = useContext(AuthContext);
  const [token, setToken] = useState("USDT");
  const [txs, setTxs] = useState();
  const [getTxs, isLoadingTxs, txError, clearError] = useResource();
  const { balance, isLoading, error } = useBalance(auth.token);
  const account = auth.userInfo.account;

  useEffect(() => {
    clearError();
    const fetchTxs = async () => {
      try {
        const response = await getTxs(
          process.env.REACT_APP_DEZENTRAL_API_BASE_URL +
            `/api/users/transactions?token=${token}`,
          {
            Authorization: `Bearer ${auth.token}`,
          }
        );
        setTxs(response);
      } catch (err) {
        console.log(err);
      }
    };
    fetchTxs();
  }, [token]);

  return (
    <DashboardTemplate>
      <div className="d-md-flex justify-content-between mb-4">
        <h5 style={{ overflowWrap: "anywhere" }}>
          <span style={{ fontWeight: "bold" }}>Cuenta: </span>
          <a
            target={"_blank"}
            rel="noreferrer"
            href={`${process.env.REACT_APP_BLOCK_EXPLORER_URL}/address/${account}`}
          >
            {account}
          </a>
        </h5>
      </div>
      <div className="d-flex align-items-center my-4 gap-3">
        <h4 className="m-0">
          <span>Saldo: </span>
          {isLoading ? (
            <span>Cargando...</span>
          ) : error ? (
            <span className="text-danger">Error!</span>
          ) : (
            <span>
              {balance ? Number(balance[token.toLowerCase()]).toFixed(5) : "0"}
            </span>
          )}
        </h4>
        <FilterDropDown
          status={token}
          setStatus={setToken}
          filters={TOKEN_FILTERS}
        />
      </div>
      <hr />
      {isLoadingTxs ? (
        <Loader />
      ) : txError ? (
        <ErrorAlert />
      ) : (
        <TxTable data={txs} wallet={account.toLowerCase()} token={token} />
      )}
    </DashboardTemplate>
  );
};

export { History };
