import * as yup from "yup";

export const registerForm = yup.object().shape({
  email: yup.string().email("Ingrese un correo válido").required("Requerido"),
  password: yup.string().required("Requerido"),
  passwordConfirmation: yup
    .string()
    .required("Requerido")
    .oneOf([yup.ref("password"), null], "La contraseña debe coincidir"),
  name: yup.string().required("Requerido"),
  phone: yup
    .string()
    .required("Requerido")
    .min(10, "Ingrese un teléfono válido"),
  referral: yup
    .string()
    .email("Ingrese un referido válido")
    .required("Requerido"),
  address: yup.string().required("Requerido"),
});
