import React, { useContext } from "react";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate } from "react-router-dom";

import logo from "../images/logo-s.png";
import { AuthContext } from "../context/auth-context";

const TopBar = ({ tabs, hide }) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const selectedClass = "bg-primary text-light rounded";

  const onLogOut = () => {
    auth.logout();
    navigate("/");
  };

  return (
    <Navbar bg="light" className={`p-2 ${hide}`} expand="lg">
      <Navbar.Brand href="#">
        <img src={logo} width="50" height="50" alt="Logo" className="mx-2" />
        Dezentral
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto"></Nav>
        <Nav className="justify-content-end gap-1">
          {tabs.map((item, index) => {
            return (
              <Link
                key={index}
                to={item.path}
                className={`nav-link text-center ${
                  pathname === item.path && selectedClass
                }`}
              >
                {item.label}
              </Link>
            );
          })}
          <Button variant="danger" onClick={onLogOut}>
            Cerrar Sesión
          </Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export { TopBar };
