import React from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import es from "react-phone-input-2/lang/es.json";

import useResource from "../hooks/useResource";
import { LoadingBtn } from "../atoms/LoadingBtn";
import { registerForm } from "../utils/schemas";

const RegisterForm = () => {
  const [createUser, isLoading, error, clearError] = useResource();
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(registerForm),
  });

  const onSubmit = async (data) => {
    clearError();
    try {
      await createUser(
        process.env.REACT_APP_DEZENTRAL_API_BASE_URL + "/api/users/signup",
        { "Content-Type": "application/json;charset=utf-8" },
        "POST",
        {
          name: data.name,
          email: data.email,
          address: data.address,
          password: data.password,
          referredBy: data.referral,
          phone: `+${data.phone}`,
        }
      );
      navigate("/processing");
    } catch (err) {
      console.log(err);
    }
  };

  const isInvalidStyle = {
    marginTop: "0.25rem",
    fontSize: ".875em",
    color: "#dc3545",
  };

  return (
    <div>
      {error && <div className="text-center text-danger">{error.message}</div>}
      <div className="register-form mt-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group mb-3">
            <input
              name="name"
              type="text"
              placeholder="Nombre"
              {...register("name")}
              className={`form-control ${errors.name ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.name?.message}</div>
          </div>

          <div className="form-group mb-3">
            <input
              name="email"
              type="text"
              placeholder="Correo"
              {...register("email")}
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.email?.message}</div>
          </div>

          <div className="form-group mb-3">
            <input
              name="address"
              type="text"
              placeholder="Dirección, Ciudad, País"
              {...register("address")}
              className={`form-control ${errors.address ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.address?.message}</div>
          </div>

          <div className="form-group mb-3">
            <Controller
              name="phone"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <PhoneInput
                  placeholder="Teléfono"
                  localization={es}
                  preferredCountries={["co", "mx"]}
                  {...field}
                  inputClass={`form-control ${
                    errors.phone ? "is-invalid" : ""
                  }`}
                />
              )}
            />
            <div style={isInvalidStyle}>{errors.phone?.message}</div>
          </div>

          <div className="form-group mb-3">
            <input
              name="referral"
              type="text"
              placeholder="Email de quien lo refiere"
              {...register("referral")}
              className={`form-control ${errors.referral ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.referral?.message}</div>
          </div>

          <div className="form-group mb-3">
            <input
              name="password"
              type="password"
              placeholder="Contraseña"
              {...register("password")}
              className={`form-control ${errors.password ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.password?.message}</div>
          </div>

          <div className="form-group mb-3">
            <input
              name="passwordConfirmation"
              type="password"
              placeholder="Confirmar contraseña"
              {...register("passwordConfirmation")}
              className={`form-control ${
                errors.passwordConfirmation ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">
              {errors.passwordConfirmation?.message}
            </div>
          </div>
          <div className="form-group mb-3">
            {isLoading ? (
              <LoadingBtn className="w-100" />
            ) : (
              <button type="submit" className="btn btn-primary w-100">
                Crear
              </button>
            )}
          </div>
        </form>
      </div>
      <div className="text-center mt-3">
        Ya tiene cuenta?{" "}
        <span>
          <Link to="/login">Iniciar Sesión</Link>
        </span>
      </div>
    </div>
  );
};

export { RegisterForm };
