import React from "react";

import { TopBar } from "../molecules";

const PAGES = [
  {
    path: "/admin/requests",
    label: "Solicitudes",
  },
  {
    path: "/admin/users",
    label: "Usuarios",
  },
  {
    path: "/admin/history",
    label: "Historial",
  },
  {
    path: "/admin/txs",
    label: "Transacciones",
  },
];

const DashboardTemplate = ({ children }) => {
  return (
    <div style={{ height: "100vh" }}>
      <TopBar tabs={PAGES} />
      <div className="p-5">{children}</div>
    </div>
  );
};

export { DashboardTemplate };
