import React from "react";
import Image from "react-bootstrap/Image";

import { PageTemplate } from "../organisms";
import wachat from "../images/wachat.png";

const Support = () => {
  const phone = process.env.REACT_APP_SUPPORT_PHONE;
  const email = process.env.REACT_APP_SUPPORT_EMAIL;

  return (
    <PageTemplate>
      <h3 className="text-muted">Soporte</h3>
      <div className="mt-4">
        <p className="text-muted">
          Si tiene inquietudes o necesita ayuda, disponemos de los siguientes
          canales de soporte:
        </p>
        <h5 className="text-muted">Whatsapp</h5>
        <a
          aria-label="Chat on WhatsApp"
          href={`https://wa.me/${phone}`}
          target="_blank"
          rel="noreferrer"
        >
          <Image
            src={wachat}
            alt="Chat on WhatsApp"
            style={{ width: "200px" }}
            className="mb-4"
          />
        </a>
        <h5 className="text-muted">Correo</h5>
        <a href={`mailto:${email}`}>{email}</a>
      </div>
    </PageTemplate>
  );
};

export { Support };
