import { useState, useCallback, useEffect } from "react";

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [serviceInfo, setServiceInfo] = useState({});
  const [tokenExpirationDate, setTokenExpirationDate] = useState();

  const login = useCallback(
    (userInfoData, token, serviceInfoData, expirationDate) => {
      setUserInfo(userInfoData);
      setToken(token);
      setServiceInfo(serviceInfoData);
      const tokenExpirationDate =
        expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 12); // Token expires in 12h
      setTokenExpirationDate(tokenExpirationDate);
      localStorage.setItem(
        "userData",
        JSON.stringify({
          userInfo: userInfoData,
          token: token,
          expiration: tokenExpirationDate.toISOString(),
        })
      );
      localStorage.setItem("serviceInfo", JSON.stringify(serviceInfoData));
    },
    []
  );

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    localStorage.clear();
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    const storedServiceData = JSON.parse(localStorage.getItem("serviceInfo"));
    if (
      storedUserData &&
      storedUserData.token &&
      new Date(storedUserData.expiration) > new Date()
    ) {
      login(
        storedUserData.userInfo,
        storedUserData.token,
        storedServiceData,
        new Date(storedUserData.expiration)
      );
    }
  }, [login]);

  return { token, login, logout, userInfo, serviceInfo };
};
