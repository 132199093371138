import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";

import { DashboardTemplate, Modal } from "../organisms";
import { UsersTable, FilterDropDown } from "../molecules";
import { ErrorAlert, Loader } from "../atoms";
import useResource from "../hooks/useResource";
import { AuthContext } from "../context/auth-context";

const STATUS_FILTERS = [
  {
    title: "Sin verificar",
    state: false,
  },
  {
    title: "Verificados",
    state: true,
  },
];

const Users = () => {
  const [isApproved, setIsApproved] = useState(false);
  const [userData, setUserData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [users, setUsers] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [getUsers, isLoading, error] = useResource();
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getUsers(
          process.env.REACT_APP_DEZENTRAL_API_BASE_URL +
            `/api/users?isApproved=${isApproved}`,
          {
            Authorization: `Bearer ${auth.token}`,
          }
        );
        setUsers(response);
      } catch (err) {
        console.log(err);
      }
    };
    fetchUsers();
  }, [isApproved, isUpdated]);

  const updateList = () => setIsUpdated(!isUpdated);

  const dataFilter = (data, status) => {
    if (data) {
      return data.filter((item) => item.isApproved === isApproved);
    }
  };

  return (
    <DashboardTemplate>
      <div className="d-flex flex-column flex-md-row gap-4 mb-4">
        <FilterDropDown
          status={isApproved}
          setStatus={setIsApproved}
          filters={STATUS_FILTERS}
        />
        <div>
          <Button variant="success" onClick={updateList}>
            Actualizar
          </Button>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <ErrorAlert />
      ) : (
        <UsersTable
          data={dataFilter(users)}
          setShowModal={setShowModal}
          setUserData={setUserData}
          isApproved={isApproved}
        />
      )}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        data={userData ? userData : {}}
        updateList={updateList}
      />
    </DashboardTemplate>
  );
};

export { Users };
