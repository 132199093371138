import React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

import { Logo } from "../atoms";

const Processing = () => {
  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div className="px-4 py-5 my-5 text-center flex-grow-1">
        <Logo />
        <p className="lead mb-4">
          Su aplicación está siendo procesada. Revise mas tarde.
        </p>
        <Link to="/">
          <button type="button" className="btn btn-primary btn-lg">
            Regresar
          </button>
        </Link>
      </div>
    </Container>
  );
};

export { Processing };
