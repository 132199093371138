import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { CopyBtn } from "../atoms";

const UserModal = ({ show, onHide, data }) => {
  const { name, referredBy, email, isApproved, address, id, account, phone } =
    data;

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Detalles del usuario
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>ID: {id}</p>
        <p>Nombre: {name}</p>
        <p>Correo: {email}</p>
        <p>Dirección: {address}</p>
        <p>Teléfono: {phone}</p>
        <p>Referido por: {referredBy}</p>
        <p>Aprobado: {isApproved ? "SI" : "NO"}</p>
        <div className="d-flex gap-2 align-items-center mb-2">
          <div>Wallet: {account}</div>
          <CopyBtn text={account} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export { UserModal };
