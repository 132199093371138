import React from "react";
import Container from "react-bootstrap/Container";

import { SideBar, TopBar } from "../molecules";

const TABS = [
  {
    label: "Portafolio",
    path: "/portfolio",
  },
  {
    label: "Tarjeta",
    path: "/cards",
  },
  {
    label: "Perfil",
    path: "/profile",
  },
  {
    label: "Soporte",
    path: "/support",
  },
];

const PageTemplate = ({ children }) => {
  return (
    <main>
      <div className="d-flex flex-row">
        <SideBar />
        <div className="flex-grow-1">
          <Container fluid className="p-4 bg-light" style={{ height: "100vh" }}>
            <TopBar tabs={TABS} hide="d-lg-none" />
            {children}
          </Container>
        </div>
      </div>
    </main>
  );
};

export { PageTemplate };
