import React from "react";
import Table from "react-bootstrap/Table";

import { parseISODate, trimHash } from "../utils/parsing";

const WithdrawalsTable = ({ data }) => {
  if (!data || data.length === 0) {
    return (
      <h4 className="text-center text-secondary my-5">
        Aún no hay transacciones
      </h4>
    );
  }

  const sortedData = data.sort((a, b) => {
    return Date.parse(a.date) - Date.parse(b.date);
  });

  return (
    <Table borderless hover responsive size="sm">
      <thead>
        <tr>
          <th className="text-center">Fecha</th>
          <th className="text-center">Cantidad</th>
          <th className="text-center">Detalles</th>
          <th className="text-center">Estado</th>
        </tr>
      </thead>
      <tbody>
        {sortedData
          .map((item) => {
            return (
              <tr key={item._id}>
                <td className="text-center">{parseISODate(item.date)}</td>
                <td className="text-center">
                  {`${item.amount} ${item.token}`}
                </td>
                <td className="text-center">
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href={`${process.env.REACT_APP_BLOCK_EXPLORER_URL}/tx/${item.txHash}`}
                  >
                    {trimHash(item.txHash)}
                  </a>
                </td>
                <td
                  className={`text-center ${
                    item.status === "PENDING" ? "text-warning" : "text-success"
                  }`}
                >
                  {item.status === "PENDING" ? "PROCESANDO" : "COMPLETADA"}
                </td>
              </tr>
            );
          })
          .reverse()}
      </tbody>
    </Table>
  );
};

export { WithdrawalsTable };
