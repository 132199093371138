import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import { default as BTable } from "react-bootstrap/Table";

import "./Tables.css";
import { parseAmount, parseISODate, trimHash } from "../utils/parsing";
import useResource from "../hooks/useResource";
import { AuthContext } from "../context/auth-context";
import { LoadingBtn } from "../atoms/LoadingBtn";

const Table = ({ data, filter, updateList }) => {
  const auth = useContext(AuthContext);
  const [approve, isLoading] = useResource();

  const handleApproval = async (id) => {
    try {
      await approve(
        process.env.REACT_APP_DEZENTRAL_API_BASE_URL +
          `/api/users/withdrawals/${id}`,
        {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${auth.token}`,
        }
      );
      updateList();
    } catch (err) {
      console.log(err);
    }
  };

  if (!data || data.length === 0) {
    return (
      <h4 className="text-center text-secondary my-5">
        Aún no hay solicitudes
      </h4>
    );
  }

  const sortedData = data.sort((a, b) => {
    return Date.parse(b.date) - Date.parse(a.date);
  });

  return (
    <BTable bordered hover responsive>
      <thead>
        <tr>
          <th className="text-center">Fecha</th>
          <th className="text-center">Monto</th>
          <th className="text-center">Token</th>
          <th className="text-center">Transacción</th>
          <th className="text-center">Usuario</th>
          <th className="text-center">
            {filter === "PENDING" ? "Acción" : "Fecha de envío"}
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedData.map((item) => {
          return (
            <tr key={item._id}>
              <td className="text-center">{parseISODate(item.date)}</td>
              <td className="text-center">${parseAmount(item.amount)}</td>
              <td className="text-center">{item.token}</td>
              <td className="text-center">
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href={`${process.env.REACT_APP_BLOCK_EXPLORER_URL}/tx/${item.txHash}`}
                >
                  {trimHash(item.txHash)}
                </a>
              </td>
              <td className="text-center">{item.email}</td>
              <td className="text-center">
                {item.status === "PENDING" ? (
                  isLoading ? (
                    <LoadingBtn />
                  ) : (
                    <Button onClick={() => handleApproval(item._id)}>
                      Completar
                    </Button>
                  )
                ) : (
                  parseISODate(item.sent)
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </BTable>
  );
};

export { Table };
