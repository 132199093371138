import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";

import { RegisterForm } from "../organisms";
import { Logo } from "../atoms";

const Register = () => {
  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh", backgroundColor: "#38635A" }}
    >
      <Card className="p-5">
        <h2 className="text-center ">Bienvenido!</h2>
        <h3 className="text-center ">Cree un nuevo usuario</h3>
        <RegisterForm />
      </Card>
    </Container>
  );
};

export { Register };
