import { useState, useCallback, useRef, useEffect } from "react";

class HttpError extends Error {
  constructor(message, errorDetails) {
    super(message); // Add a "message" property
    this.details = errorDetails; // Adds a "details" property
  }
}

const useResource = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const activeHttpRequests = useRef([]);

  const sendRequest = useCallback(
    async (resource, headers, method = "GET", body = null) => {
      setIsLoading(true);
      const httpAbortCtrl = new AbortController();

      try {
        const response = await fetch(resource, {
          method,
          body: body && JSON.stringify(body),
          headers,
        });
        const responseData = await response.json();

        activeHttpRequests.current = activeHttpRequests.current.filter(
          (reqCtrl) => reqCtrl !== httpAbortCtrl
        );

        if (!response.ok) {
          throw new HttpError(responseData.message, responseData.details);
        }
        return responseData;
      } catch (err) {
        const { message, details } = err;
        setError({ message, details });
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      activeHttpRequests.current.forEach((abortCtrl) => abortCtrl.abort());
    };
  }, []);

  return [sendRequest, isLoading, error, clearError];
};

export default useResource;
