import React from "react";

import logo from "../images/logo.svg";

const Logo = () => {
  return (
    <img
      src={logo}
      alt="logo"
      style={{ width: "180px", height: "auto" }}
      className="mb-2"
    />
  );
};

export { Logo };
