import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

import { AuthContext } from "../context/auth-context";

const NotFound = () => {
  const auth = useContext(AuthContext);

  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div className="px-4 py-5 my-5 text-center flex-grow-1">
        <h1 className="text-primary">Error</h1>
        <p className="lead mb-4">No disponible</p>
        <Link to="/">
          <button
            type="button"
            onClick={auth.logout}
            className="btn btn-primary btn-lg"
          >
            Inicio
          </button>
        </Link>
      </div>
    </Container>
  );
};

export { NotFound };
