import React from "react";
import Image from "react-bootstrap/Image";

import cardImage from "../images/card.jpeg";
import { PageTemplate } from "../organisms";

const Cards = () => {
  return (
    <PageTemplate>
      <h3 className="text-muted">Tarjeta Débito</h3>
      <div className="mt-4">
        <a
          target={"_blank"}
          rel="noreferrer"
          href={process.env.REACT_APP_CARDS_PROVIDER_URL}
        >
          <Image src={cardImage} className="w-25" alt="card image" />
        </a>
      </div>
      <div className="mt-4">
        <h5 className="text-muted">
          <a
            target={"_blank"}
            rel="noreferrer"
            href={process.env.REACT_APP_CARDS_PROVIDER_URL}
          >
            Ver movimientos
          </a>
        </h5>
      </div>
    </PageTemplate>
  );
};

export { Cards };
