import React, { useContext } from "react";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoins,
  faCreditCard,
  faUser,
  faRightFromBracket,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "../context/auth-context";

const TABS = [
  {
    icon: faCoins,
    label: "Portafolio",
    path: "/portfolio",
  },
  {
    icon: faCreditCard,
    label: "Tarjeta",
    path: "/cards",
  },
  {
    icon: faUser,
    label: "Perfil",
    path: "/profile",
  },
  {
    icon: faQuestionCircle,
    label: "Soporte",
    path: "/support",
  },
];

const SideBar = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const selectedClass = "bg-warning text-light rounded";

  const onLogOut = () => {
    auth.logout();
    navigate("/");
  };

  return (
    <Nav id="side_bar" className="d-none d-lg-flex flex-column shadow">
      <div className="m-3">
        <Link className="text-warning my-3 text-decoration-none" to="/">
          <h4>Dezentral</h4>
        </Link>
        <hr className="text-warning" />
        <div className="d-flex flex-column gap-2">
          {TABS.map((item, index) => {
            return (
              <Link
                key={index}
                className={`text-warning text-decoration-none ${
                  pathname === item.path && selectedClass
                }`}
                to={item.path}
              >
                <div className="d-flex flex-column justify-content-center align-items-center my-3">
                  <FontAwesomeIcon icon={item.icon} />
                  <div>{item.label}</div>
                </div>
              </Link>
            );
          })}
        </div>
        <hr className="text-warning" />
        <Button
          variant="warning"
          className="text-light w-100"
          onClick={onLogOut}
        >
          <FontAwesomeIcon icon={faRightFromBracket} /> Salir
        </Button>
      </div>
    </Nav>
  );
};

export { SideBar };
