import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";

import { LoginForm } from "../organisms";

const Login = () => {
  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh", backgroundColor: "#38635A" }}
    >
      <Card className="p-5">
        <h2 className="text-center">Ingrese a su cuenta</h2>
        <LoginForm />
      </Card>
    </Container>
  );
};

export { Login };
