import React from "react";

import { LoginForm } from "../organisms";
import { Logo } from "../atoms";

const AdminLogin = () => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <Logo />
      <LoginForm admin />
    </div>
  );
};

export { AdminLogin };
