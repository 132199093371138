import React, { useContext, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { AuthContext } from "./context/auth-context";
import {
  Home,
  Portfolio,
  Login,
  Register,
  AdminLogin,
  SendToCard,
  History,
  Users,
  Processing,
  NotFound,
  Profile,
  Cards,
  AdminTxs,
  Recovery,
  PwdReset,
  Support,
} from "./screens";

const App = () => {
  const auth = useContext(AuthContext);
  const { isAdmin } = auth.userInfo;
  let routes;

  if (auth.token) {
    if (isAdmin) {
      routes = (
        <>
          <Route path="/admin/requests" element={<SendToCard />} />
          <Route path="/admin/users" element={<Users />} />
          <Route path="/admin/history" element={<History />} />
          <Route path="/admin/txs" element={<AdminTxs />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </>
      );
    } else {
      routes = (
        <>
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/cards" element={<Cards />} />
          <Route path="/register" element={<Register />} />
          <Route path="/support" element={<Support />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </>
      );
    }
  } else {
    routes = (
      <>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />;
        <Route path="/register" element={<Register />} />
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/processing" element={<Processing />} />
        <Route path="/recover_password" element={<Recovery />} />
        <Route path="/update_password/:token" element={<PwdReset />} />
        <Route path="*" element={<NotFound />} />
      </>
    );
  }

  return (
    <Suspense fallback="Loading...">
      <BrowserRouter>
        <Routes>{routes}</Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
