import React, { useState, useContext } from "react";
import { default as BTable } from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRotateRight,
  faCoins,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import "./Tables.css";
import useResource from "../hooks/useResource";
import { AuthContext } from "../context/auth-context";
import { TopUpModal, UserModal } from "../organisms";

const UsersTable = ({ data, setShowModal, setUserData, isApproved }) => {
  const auth = useContext(AuthContext);
  const [balance, setBalance] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [getBalance, isLoadingBalance] = useResource();
  const [topUp, isLoading, topUpError] = useResource();
  const authHeader = {
    Authorization: `Bearer ${auth.token}`,
  };
  const URL = process.env.REACT_APP_DEZENTRAL_API_BASE_URL + "/api/users";

  if (!data || data.length === 0) {
    return (
      <h4 className="text-center text-secondary my-5">
        Aún no hay solicitudes
      </h4>
    );
  }

  const handleClick = (item) => {
    setUserData(item);
    setShowModal(true);
  };

  const handleUserModal = (item) => {
    setSelectedUser(item);
    setShowUserModal(true);
  };

  const handleHideUserModal = () => {
    setShowUserModal(false);
  };

  const handleTopUpClick = (item) => {
    setSelectedUser({ ...item, balance: balance[item.id] });
    setShowTopUpModal(true);
  };

  const checkBalance = async (data) => {
    const { id } = data;
    try {
      const response = await getBalance(`${URL}/balances?id=${id}`, authHeader);
      const { usdt, eth, busd } = response;
      setBalance({ ...balance, [id]: { usdt, bnb: eth, busd } });
    } catch (err) {
      console.log(err);
    }
  };

  const bnbTopUp = async (data) => {
    try {
      await topUp(`${URL}/topupbnb/${data.account}`, authHeader);
    } catch (err) {
      console.log(err);
    }
  };

  const Balance = ({ data, token }) => {
    const decimals = token === "usdt" || token === "busd" ? 2 : 4;
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="text-secondary">
          {balance[data.id]
            ? parseFloat(balance[data.id][token]).toFixed(decimals)
            : "??"}
        </div>
      </div>
    );
  };

  const Controls = ({ data }) => {
    const hasCheckedBalance = balance[data.id];

    return (
      <div className="d-flex flex-row gap-1 justify-content-center align-items-center">
        <Button
          disabled={isLoadingBalance}
          onClick={() => checkBalance(data)}
          size="sm"
        >
          <FontAwesomeIcon icon={faRotateRight} />
        </Button>
        <Button
          disabled={isLoading || !hasCheckedBalance}
          onClick={() => handleTopUpClick(data)}
          size="sm"
          variant="danger"
        >
          <FontAwesomeIcon icon={faCoins} />
        </Button>
        <Button
          onClick={() => handleUserModal(data)}
          size="sm"
          variant="success"
        >
          <FontAwesomeIcon icon={faUser} />
        </Button>
      </div>
    );
  };

  const VerifyButton = ({ data }) => {
    const hasEnoughBalance = balance[data.id] && balance[data.id]["bnb"] > 0;

    if (!isApproved) {
      return (
        <Button disabled={!hasEnoughBalance} onClick={() => handleClick(data)}>
          Aprobar
        </Button>
      );
    }
    return <div>Verificado</div>;
  };

  return (
    <BTable bordered hover responsive>
      <TopUpModal
        show={showTopUpModal}
        onHide={() => setShowTopUpModal(false)}
        data={selectedUser}
        action={bnbTopUp}
        isLoading={isLoading}
        error={topUpError}
        amount={auth.serviceInfo.bnbTopUpUsdAmount}
      />
      <UserModal
        show={showUserModal}
        onHide={handleHideUserModal}
        data={selectedUser}
      />
      <thead>
        <tr>
          <th className="text-center">Nombre</th>
          <th className="text-center">Email</th>
          {isApproved && <th className="text-center">Tarjeta</th>}
          <th className="text-center">Saldo USDT</th>
          <th className="text-center">Saldo BUSD</th>
          <th className="text-center">Saldo BNB</th>
          <th className="text-center">Acciones</th>
          <th className="text-center">Verificación</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) => {
          return (
            <tr key={index}>
              <td className="text-center">{item.name}</td>
              <td className="text-center">{item.email}</td>
              {isApproved && <td className="text-center">{item.card}</td>}
              <td className="text-center">
                <Balance data={item} token="usdt" />
              </td>
              <td className="text-center">
                <Balance data={item} token="busd" />
              </td>
              <td className="text-center">
                <Balance data={item} token="bnb" />
              </td>
              <td className="text-center">
                <Controls data={item} />
              </td>
              <td className="text-center text-success">
                <VerifyButton data={item} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </BTable>
  );
};

export { UsersTable };
