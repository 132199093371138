import React, { useContext } from "react";

import { PageTemplate, Balance } from "../organisms";
import { AuthContext } from "../context/auth-context";
import { useBalance } from "../hooks/useBalance";

const Portfolio = () => {
  const auth = useContext(AuthContext);
  const { balance, isLoading } = useBalance(auth.token);

  return (
    <PageTemplate>
      <Balance data={balance} isLoading={isLoading} />
    </PageTemplate>
  );
};

export { Portfolio };
